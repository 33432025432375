import React, { useEffect, useRef, useState } from 'react';
import { 
  Container, Typography, Button, Box, TextField, MenuItem, 
  LinearProgress, Select, FormControl, InputLabel, Tooltip, 
  Paper, Grid, IconButton, AppBar, Toolbar, Modal, ThemeProvider, CssBaseline, createTheme
} from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import RefreshIcon from '@mui/icons-material/Refresh';
import HelpIcon from '@mui/icons-material/Help';

import { jsPDF } from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format, parseISO } from "date-fns";
import { createMRTColumnHelper } from 'material-react-table';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';
import Header from '../Header';
import Footer from '../Footer';
// import userGuidePdf from './UserGuide.pdf';
GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.min.js`;
// Create a theme with Roboto font
const theme = createTheme({
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  palette: {
    primary: {
      main: '#27AAE1', // Blue from the branding study
    },
    secondary: {
      main: '#39B54A', // Green from the branding study
    },
  },
});
const columnHelper = createMRTColumnHelper();
const CustomExposureFilter = ({ column }) => (
  <div>
    <TextField
      select
      label="Degree of Exposure"
      value={column.getFilterValue() || ''}
      onChange={(e) => column.setFilterValue(e.target.value)}
      variant="standard"
      size="small"
      fullWidth
    >
      <MenuItem value="">All</MenuItem>
      <MenuItem value="1">1st Degree</MenuItem>
      <MenuItem value="2">2nd Degree</MenuItem>
    </TextField>
    <Typography variant="caption" display="block">
      Filter by degree of exposure (1st Degree or 2nd Degree).
    </Typography>
  </div>
);
const columns = [
  columnHelper.accessor('id', {
    header: 'ID',
    size: 250,
    minSize: 50,
    maxSize: 200,
    grow: 1,
    sortingFn: 'basic',
    Filter: ({ column }) => {
      const [filterMode, setFilterMode] = useState('between');
      const [minValue, setMinValue] = useState('');
      const [maxValue, setMaxValue] = useState('');
  
      useEffect(() => {
        const filterValue = column.getFilterValue();
        if (Array.isArray(filterValue)) {
          setMinValue(filterValue[0] || '');
          setMaxValue(filterValue[1] || '');
        } else if (filterValue) {
          setMinValue(filterValue);
        }
      }, [column.getFilterValue()]);
  
      const handleFilterChange = () => {
        switch (filterMode) {
          case 'between':
            column.setFilterValue([minValue, maxValue]);
            break;
          case 'greaterThan':
            column.setFilterValue([minValue, undefined]);
            break;
          case 'lessThan':
            column.setFilterValue([undefined, maxValue]);
            break;
          default:
            column.setFilterValue(null);
        }
      };
  
      return (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Filter ID
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Filter Type</InputLabel>
            <Select
              value={filterMode}
              onChange={(e) => setFilterMode(e.target.value)}
              label="Filter Type"
            >
              <MenuItem value="between">Between</MenuItem>
              <MenuItem value="greaterThan">Greater Than</MenuItem>
              <MenuItem value="lessThan">Less Than</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="caption" display="block" gutterBottom>
            {filterMode === 'between'
              ? "Enter a minimum and maximum ID to filter records within that range."
              : filterMode === 'greaterThan'
              ? "Enter a value to filter records with IDs greater than it."
              : "Enter a value to filter records with IDs less than it."}
          </Typography>
          {(filterMode === 'between' || filterMode === 'greaterThan') && (
            <TextField
              label={filterMode === 'between' ? "Minimum ID" : "Greater Than"}
              type="number"
              value={minValue}
              onChange={(e) => setMinValue(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          {(filterMode === 'between' || filterMode === 'lessThan') && (
            <TextField
              label={filterMode === 'between' ? "Maximum ID" : "Less Than"}
              type="number"
              value={maxValue}
              onChange={(e) => setMaxValue(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          <Button variant="contained" onClick={handleFilterChange} fullWidth sx={{ mt: 2 }}>
            Apply Filter
          </Button>
          {column.getFilterValue() && (
            <Button variant="outlined" onClick={() => column.setFilterValue(null)} fullWidth sx={{ mt: 1 }}>
              Clear Filter
            </Button>
          )}
        </Box>
      );
    },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const rowValue = row.getValue(columnId);
      const [min, max] = filterValue;
      if (min !== undefined && max !== undefined) {
        return rowValue >= min && rowValue <= max;
      } else if (min !== undefined) {
        return rowValue >= min;
      } else if (max !== undefined) {
        return rowValue <= max;
      }
      return true;
    },
  }),
  columnHelper.accessor('time', {
    accessorFn: (row) => new Date(row.time),
    id: 'time',
    header: 'Exposure Time (yyyy-MM-dd HH:mm:ss CT)',
    size: 350,
    minSize: 200,
    maxSize: 600,
    grow: 2,
    sortingFn: 'datetime',
    Cell: ({ cell }) => format(cell.getValue(), 'yyyy-MM-dd HH:mm:ss'),
    Header: ({ column }) => <em>{column.columnDef.header}</em>,
    Filter: ({ column }) => {
      const [filterMode, setFilterMode] = useState('betweenInclusive');
      const [startDate, setStartDate] = useState(null);
      const [endDate, setEndDate] = useState(null);
  
      useEffect(() => {
        const filterValue = column.getFilterValue();
        if (Array.isArray(filterValue)) {
          setStartDate(filterValue[0]);
          setEndDate(filterValue[1]);
        } else if (filterValue) {
          setStartDate(filterValue);
        }
      }, [column.getFilterValue()]);
  
      const handleFilterChange = () => {
        switch (filterMode) {
          case 'betweenInclusive':
            column.setFilterValue([startDate, endDate]);
            break;
          case 'lessThan':
            column.setFilterValue([null, startDate]);
            break;
          case 'greaterThan':
            column.setFilterValue([startDate, null]);
            break;
          default:
            column.setFilterValue(null);
        }
      };
  
      return (
        <div id="survey-report-container">
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Filter Exposure Time
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Filter Type</InputLabel>
            <Select
              value={filterMode}
              onChange={(e) => setFilterMode(e.target.value)}
              label="Filter Type"
            >
              <MenuItem value="betweenInclusive">Between (Inclusive)</MenuItem>
              <MenuItem value="lessThan">Before</MenuItem>
              <MenuItem value="greaterThan">After</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="caption" display="block" gutterBottom>
            {filterMode === 'betweenInclusive'
              ? "Select a start and end date to filter exposures within that range."
              : filterMode === 'lessThan'
              ? "Select a date to filter exposures occurring before it."
              : "Select a date to filter exposures occurring after it."}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            {(filterMode === 'betweenInclusive' || filterMode === 'greaterThan') && (
              <DateTimePicker
                label={filterMode === 'betweenInclusive' ? "Start Date" : "After Date"}
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            )}
            {filterMode === 'betweenInclusive' && (
              <DateTimePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            )}
            {filterMode === 'lessThan' && (
              <DateTimePicker
                label="Before Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                renderInput={(params) => <TextField {...params} fullWidth margin="normal" />}
              />
            )}
          </LocalizationProvider>
          <Button variant="contained" onClick={handleFilterChange} fullWidth sx={{ mt: 2 }}>
            Apply Filter
          </Button>
          {column.getFilterValue() && (
            <Button variant="outlined" onClick={() => column.setFilterValue(null)} fullWidth sx={{ mt: 1 }}>
              Clear Filter
            </Button>
          )}
        </Box>
        <Footer/>
        </div>
      );
    },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const rowValue = row.getValue(columnId);
      const [start, end] = filterValue;
      if (start && end) {
        return rowValue >= start && rowValue <= end;
      } else if (start) {
        return rowValue >= start;
      } else if (end) {
        return rowValue <= end;
      }
      return true;
    },
  }),
  columnHelper.accessor('exposure', {
    header: 'Degree of Exposure',
    size: 300,
    minSize: 100,
    maxSize: 800,
    grow: 1,
    Filter: CustomExposureFilter,
  }),
  columnHelper.accessor('duration', {
    header: 'Exposure Duration (seconds)',
    size: 350,
    minSize: 150,
    maxSize: 500,
    grow: 1,
    Cell: ({ cell }) => (cell.getValue() / 1).toFixed(3),
    Filter: ({ column }) => {
      const [filterMode, setFilterMode] = useState('between');
      const [minValue, setMinValue] = useState('');
      const [maxValue, setMaxValue] = useState('');
  
      useEffect(() => {
        const filterValue = column.getFilterValue();
        if (Array.isArray(filterValue)) {
          setMinValue(filterValue[0] || '');
          setMaxValue(filterValue[1] || '');
        } else if (filterValue) {
          setMinValue(filterValue);
        }
      }, [column.getFilterValue()]);
  
      const handleFilterChange = () => {
        switch (filterMode) {
          case 'between':
            column.setFilterValue([minValue, maxValue]);
            break;
          case 'greaterThan':
            column.setFilterValue([minValue, undefined]);
            break;
          case 'lessThan':
            column.setFilterValue([undefined, maxValue]);
            break;
          default:
            column.setFilterValue(null);
        }
      };
  
      return (
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Filter Duration
          </Typography>
          <FormControl fullWidth margin="normal">
            <InputLabel>Filter Type</InputLabel>
            <Select
              value={filterMode}
              onChange={(e) => setFilterMode(e.target.value)}
              label="Filter Type"
            >
              <MenuItem value="between">Between</MenuItem>
              <MenuItem value="greaterThan">Greater Than</MenuItem>
              <MenuItem value="lessThan">Less Than</MenuItem>
            </Select>
          </FormControl>
          <Typography variant="caption" display="block" gutterBottom>
            {filterMode === 'between'
              ? "Enter a minimum and maximum duration to filter records within that range."
              : filterMode === 'greaterThan'
              ? "Enter a value to filter records with durations greater than it."
              : "Enter a value to filter records with durations less than it."}
          </Typography>
          {(filterMode === 'between' || filterMode === 'greaterThan') && (
            <TextField
              label={filterMode === 'between' ? "Minimum Duration (seconds)" : "Greater Than Duration (seconds)"}
              type="number"
              value={minValue}
              onChange={(e) => setMinValue(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          {(filterMode === 'between' || filterMode === 'lessThan') && (
            <TextField
              label={filterMode === 'between' ? "Maximum Duration (seconds)" : "Less Than Duration (seconds)"}
              type="number"
              value={maxValue}
              onChange={(e) => setMaxValue(e.target.value)}
              fullWidth
              margin="normal"
            />
          )}
          <Button variant="contained" onClick={handleFilterChange} fullWidth sx={{ mt: 2 }}>
            Apply Filter
          </Button>
          {column.getFilterValue() && (
            <Button variant="outlined" onClick={() => column.setFilterValue(null)} fullWidth sx={{ mt: 1 }}>
              Clear Filter
            </Button>
          )}
        </Box>
      );
    },
    filterFn: (row, columnId, filterValue) => {
      if (!filterValue) return true;
      const rowValue = row.getValue(columnId) ; // Convert milliseconds to seconds
      const [min, max] = filterValue;
      if (min !== undefined && max !== undefined) {
        return rowValue >= min && rowValue <= max;
      } else if (min !== undefined) {
        return rowValue >= min;
      } else if (max !== undefined) {
        return rowValue <= max;
      }
      return true;
    },
  }),
];

const formatData = (res) => {
  var ret = [];
  for (var i = 0; i < res.reportTable.table_id.length; i++) {
    var formattedTime = format(new Date(res.reportTable.table_time_of_exposure[i]), `yyyy-MM-dd HH:mm:ss`);
    var durationInSeconds = (res.reportTable.table_exposure_duration[i] / 1000).toFixed(3);
    var temp = { 
      id: res.reportTable.table_id[i], 
      time: formattedTime, 
      exposure: res.reportTable.table_exposure_degree[i], 
      duration: durationInSeconds 
    }
    ret.push(temp);
  }
  return ret;
};

const handleExportRows = (rows, exportFormat) => {
  const formData = JSON.parse(localStorage.getItem("formData"));
  const dateTo = new Date(formData['date-to']);
  const dateFrom = new Date(formData['date-from']);
  const formattedDateFrom = format(dateFrom, 'MMMM do, yyyy');
  const formattedDateTo = format(dateTo, 'MMMM do, yyyy');
  const fileName = `ActiveTrace_Report_${formattedDateFrom}-${formattedDateTo}`;
  const diseaseName = formData.disease || 'Custom'; // Get the disease name

  if (exportFormat === 'pdf') {
    const doc = new jsPDF('p', 'pt', 'a4');
    const distanceUnit = localStorage.getItem("distanceUnit");
    const durationUnit = localStorage.getItem("durationUnit");
    const dateFrom = new Date(formData['date-from']);
    dateFrom.setDate(dateFrom.getDate() + 1);
    const formattedDateFrom = format(dateFrom, 'MMMM do, yyyy');
    const dateTo = new Date(formData['date-to']);
    dateTo.setDate(dateTo.getDate() + 1);
    const formattedDateTo = format(dateTo, 'MMMM do, yyyy');
   
    doc.setFontSize(20);
    doc.text(`ActiveTrace Positive Contact Report, `, 40, 40);
    doc.text(`${formattedDateFrom} - ${formattedDateTo}`, 40, 60);
    doc.setFontSize(16);
    doc.text('Contact Trace Parameters:', 40, 100);
    doc.setFontSize(12);
    doc.text(`Disease: ${diseaseName}`, 40, 130);
    doc.text(`Caregiver/Patient ID: ${formData.target}`, 40, 150);
    doc.text(`Degree (1st or 2nd degree): ${formData.degree}`, 40, 170);
    doc.text(`Distance: ${formData.distance} ${formData.distanceUnit}`, 40, 190);
    doc.text(`Duration: ${formData.duration} ${formData.durationUnit}`, 40, 210);
    doc.setFontSize(14);
    doc.text('Possible Infected Contacts', 40, 240);

    const tableData = rows.map(row => [
      row.original.id,
      format(new Date(row.original.time), 'yyyy-MM-dd HH:mm:ss'),
      row.original.exposure,
      row.original.duration
    ]);
    const headers = ['ID', 'Exposure Time (yyyy-MM-dd HH:mm:ss CT)', 'Degree of Exposure', 'Exposure Duration (seconds)'];

    autoTable(doc, {
      startY: 260,
      head: [headers],
      body: tableData,
      styles: {
        fontSize: 10,
      },
    });

    doc.save(`${fileName}.pdf`);

  } else if (exportFormat === 'csv') {
    const csvRows = [];
    
    // Add headers with input parameters
    csvRows.push(`Disease,${diseaseName}`);
    csvRows.push(`Caregiver/Patient ID,${formData.target}`);
    csvRows.push(`Degree,${formData.degree}`);
    csvRows.push(`Distance,${formData.distance} ${formData.distanceUnit}`);
    csvRows.push(`Duration,${formData.duration} ${formData.durationUnit}`);
    csvRows.push(`Date Range,${formattedDateFrom} - ${formattedDateTo}`);
    csvRows.push(''); // Empty row for separation

    const headers = ['ID', 'Exposure Time (yyyy-MM-dd HH:mm:ss CT)', 'Degree of Exposure', 'Exposure Duration ($formData.durationUnit)'];
    csvRows.push(headers.join(','));
    rows.forEach(row => {
      const values = [
        row.original.id,
        format(new Date(row.original.time), 'yyyy-MM-dd HH:mm:ss'),
        row.original.exposure,
        row.original.duration
      ];
      csvRows.push(values.join(','));
    });
    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};


const ReportResults = () => {
  const [payloadParams, setPayloadParams] = useState(null);
  const [datas, setDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [exportFormat, setExportFormat] = useState('pdf');
  const [formData, setFormData] = useState(null);
  const tableRef = useRef(null);
  const navigate = useNavigate();
 

  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    setLoading(true);
    var rets = localStorage.getItem("convertedFormData");
    
    if (rets == null || rets === "0") {
      setError("No data found. Please go back and submit the form again.");
      setLoading(false);
      return;
    }

    var formDataRet = localStorage.getItem("formData");
    if (formDataRet === null){return;}
    setFormData(JSON.parse(formDataRet))
    var data = JSON.parse(rets);
    
    setPayloadParams(JSON.parse(formDataRet));
    
    // Retrieve the selected disease from localStorage
    const selectedDisease = localStorage.getItem("diseaseName") || 'Custom';
  
  
  
    const token = sessionStorage.getItem("token");
    const database = sessionStorage.getItem("database");
    try {
      const postResponse = await fetch(`${database}/receive-data?token=${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
      });
      if (postResponse.ok) {
        const result = await postResponse.json();
        setDatas(formatData(result));
        setLoading(false);
        setError(null);
      } else if (postResponse.status === 500) {
        console.error('Server error: 500');
        setError('Please check if your inputs are in the correct format and try again.');
        setLoading(false);
      } else {
        console.error('Failed to submit data');
        setError('An unexpected error occurred. Please try again.');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setError('A network error occurred. Please check your connection and try again.');
      setLoading(false);
    }
  };

  const renderPayloadParams = () => {
    if (!payloadParams) return null;

    return (
      <Grid container spacing={2} justifyContent="center">
      <Grid item xs={12} sm={6}>
        <Typography><strong>Disease:</strong> {localStorage.getItem("diseaseName") || 'N/A'}</Typography>
        <Typography><strong>Caregiver/Patient ID:</strong> {payloadParams.target}</Typography>
        <Typography><strong>Time Period Start:</strong> {format(parseISO(payloadParams['date-from']), 'M/d/yyyy h:mm a')}</Typography>
        <Typography><strong>Time Period End:</strong> {format(parseISO(payloadParams['date-to']), 'M/d/yyyy h:mm a')}</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography><strong>Distance:</strong> {payloadParams.distance} {payloadParams.distanceUnit}</Typography>
        <Typography><strong>Duration:</strong> {payloadParams.duration} {payloadParams.durationUnit}</Typography>
        <Typography><strong>Contact Degree:</strong> {payloadParams.degree === 1 ? '1st' : '2nd'}</Typography>
      </Grid>
      </Grid>
    );
  };
  const handleExportRows = (rows, exportFormat) => {
    const formData = JSON.parse(localStorage.getItem("formData"));
    const dateTo = new Date(formData['date-to']);
    const dateFrom = new Date(formData['date-from']);
    const formattedDateFrom = format(dateFrom, 'MMMM do, yyyy');
    const formattedDateTo = format(dateTo, 'MMMM do, yyyy');
    const formattedDateFromCSV = format(dateFrom, 'MMMM do yyyy');
    const formattedDateToCSV = format(dateTo, 'MMMM do yyyy');
    const fileName = `ActiveTrace_Report_${formattedDateFrom}-${formattedDateTo}`;
    const diseaseName = formData.disease || 'Custom'; // Get the disease name
  
    if (exportFormat === 'pdf') {
      const doc = new jsPDF('p', 'pt', 'a4');
      const distanceUnit = localStorage.getItem("distanceUnit");
      const durationUnit = localStorage.getItem("durationUnit");
      const dateFrom = new Date(formData['date-from']);
      dateFrom.setDate(dateFrom.getDate() + 1);
      const formattedDateFrom = format(dateFrom, 'MMMM do, yyyy');
      const dateTo = new Date(formData['date-to']);
      dateTo.setDate(dateTo.getDate() + 1);
      const formattedDateTo = format(dateTo, 'MMMM do, yyyy');
     
      doc.setFontSize(20);
      doc.text(`ActiveTrace Positive Contact Report, `, 40, 40);
      doc.text(`${formattedDateFrom} - ${formattedDateTo}`, 40, 60);
      doc.setFontSize(16);
      doc.text('Contact Trace Parameters:', 40, 100);
      doc.setFontSize(12);
      doc.text(`Disease: ${diseaseName}`, 40, 130);
      doc.text(`Caregiver/Patient ID: ${formData.target}`, 40, 150);
      doc.text(`Degree (1st or 2nd degree): ${formData.degree}`, 40, 170);
      doc.text(`Distance: ${formData.distance} ${formData.distanceUnit}`, 40, 190);
      doc.text(`Duration: ${formData.duration} ${formData.durationUnit}`, 40, 210);
      doc.setFontSize(14);
      doc.text('Possible Infected Contacts', 40, 240);
  
      const tableData = rows.map(row => [
        row.original.id,
        format(new Date(row.original.time), 'yyyy-MM-dd HH:mm:ss'),
        row.original.exposure,
        row.original.duration
      ]);
      const headers = ['ID', 'Exposure Time (yyyy-MM-dd HH:mm:ss CT)', 'Degree of Exposure', 'Exposure Duration (seconds)'];
  
      autoTable(doc, {
        startY: 260,
        head: [headers],
        body: tableData,
        styles: {
          fontSize: 10,
        },
      });
  
      doc.save(`${fileName}.pdf`);
  
    } else if (exportFormat === 'csv') {
      const csvRows = [];
      
      // Add headers with input parameters
      csvRows.push(`Disease,${diseaseName}`);
      csvRows.push(`Caregiver/Patient ID,${formData.target}`);
      csvRows.push(`Degree,${formData.degree}`);
      csvRows.push(`Distance,${formData.distance} ${formData.distanceUnit}`);
      csvRows.push(`Duration,${formData.duration} ${formData.durationUnit}`);
      csvRows.push(`Date Range,${formattedDateFromCSV} - ${formattedDateToCSV}`);
      csvRows.push(''); // Empty row for separation
  
      const headers = ['ID', 'Exposure Time (yyyy-MM-dd HH:mm:ss CT)', 'Degree of Exposure', 'Exposure Duration (seconds)'];
      csvRows.push(headers.join(','));
      rows.forEach(row => {
        const values = [
          row.original.id,
          format(new Date(row.original.time), 'yyyy-MM-dd HH:mm:ss'),
          row.original.exposure,
          row.original.duration
        ];
        csvRows.push(values.join(','));
      });
      const csvString = csvRows.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('hidden', '');
      a.setAttribute('href', url);
      a.setAttribute('download', `${fileName}.csv`);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  
  

  const table = useMaterialReactTable({
    columns,
    data: datas,
    getRowId: (row) => row.id,
    initialState: { showColumnFilters: true },
    enableRowSelection: true,
    enableColumnFilterModes: true,
    columnFilterDisplayMode: 'subheader',
    paginationDisplayMode: 'pages',
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
          width: '100%',
        }}
      >
        <FormControl variant="standard" sx={{ minWidth: 120 }}>
          <InputLabel id="export-format-label">Export Format</InputLabel>
          <Select
            labelId="export-format-label"
            id="export-format"
            value={exportFormat}
            onChange={(e) => setExportFormat(e.target.value)}
            label="Export Format"
          >
            <MenuItem value="pdf">PDF</MenuItem>
            <MenuItem value="csv">CSV</MenuItem>
          </Select>
        </FormControl>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getPrePaginationRowModel().rows, exportFormat)}
          startIcon={<FileDownloadIcon />}
        >
          Export All Rows
        </Button>
        <Button
          disabled={table.getRowModel().rows.length === 0}
          onClick={() => handleExportRows(table.getRowModel().rows, exportFormat)}
          startIcon={<FileDownloadIcon />}
        >
          Export Page Rows
        </Button>
        <Button
          disabled={
            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
          }
          onClick={() => handleExportRows(table.getSelectedRowModel().rows, exportFormat)}
          startIcon={<FileDownloadIcon />}
        >
          Export Selected Rows
        </Button>
      </Box>
    ),
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', position: 'relative', pb: '74px' }}>
        <Container maxWidth={false} sx={{ mt: 4, mb: 4, flexGrow: 1 }}>
          <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <Typography variant="h4" align="center" gutterBottom sx={{ 
              fontWeight: 'bold', 
              textShadow: '1px 1px 2px rgba(0,0,0,0.1)',
              mb: 3
            }}>
              Potential Infected Contacts List
            </Typography>
            </Box>
            {renderPayloadParams()}
          </Paper>
         
          {loading ? (
            <LinearProgress />
          ) : error ? (
            <Box textAlign="center">
              <Typography color="error" paragraph>There was an error while fetching data. Please try again.</Typography>
              <Button variant="contained" color="primary" onClick={fetchData}>
                Retry
              </Button>
            </Box>
          ) : (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Box ref={tableRef} sx={{ height: 'calc(100vh - 368px)', minHeight: '400px', width: '100%', overflowX: 'auto' }}>
              <MaterialReactTable 
                table={table}
                messages={{
                  noData: 'No contacts found',
                }}
                muiTableProps={{
                  sx: {
                    tableLayout: 'fixed',
                  },
                }}
                muiTableBodyCellProps={{
                  sx: {
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  },
                }}
                defaultColumn={{
                  minSize: 50,
                  maxSize: 1000,
                  size: 150,
                }}
              />

              </Box>
            </LocalizationProvider>
          )}
        </Container>
        <AppBar
          position="fixed"
          color="default"
          sx={{
            top: 'auto',
            bottom: 24,
            height: 50,
            marginBottom:'10px',
            bgcolor: 'background.paper',
            boxShadow: 'none',
            borderTop: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Toolbar sx={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <Button
              variant="contained"
              startIcon={<ExitToAppIcon />}
              onClick={() => navigate('/contact-report')}
              sx={{
                bgcolor: '#EF4136',
                '&:hover': { bgcolor: '#D13026' },
                color: 'white',
              }}
            >
              EXIT REPORT
            </Button>
          </Toolbar>
        </AppBar>

        {/* Help Modal */}
        
      </Box>
      
    </ThemeProvider>
  );
}

export default ReportResults;