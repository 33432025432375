import React, { Component } from 'react';
import saveFile from 'save-as-file';
import '../common.css';
import './ContactReport.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Main from '../../components/ReportForm/Main.tsx'
import {setRefreshInterval, clearRefreshInterval} from '../RefreshInterval';
import ReportForm from '../../components/ReportForm/ReportForm.tsx';
import ReportResults from '../../components/ReportForm/ReportResults.tsx';

class ContactReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            surveys: [],
            sites: [],
            report_data: [],
        };
    }

    render() {
        const {report_data} = this.state;
        
        return (
            <div id="survey-report-container">

                <Header />

                {/* <div className="page-title">{pageTitle}</div>  */}

                <div className="survey-reports-wrapper">
                    <ReportForm/>
                </div>
                
                <Footer />
            </div>
        );
    }
}

export default ContactReport;
