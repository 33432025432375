import React, {Component} from 'react';
import Header from '../../components/Header';
import Footer from '../../components/Footer'
import ReportResults from '../../components/ReportForm/ReportResults.tsx';


class ContactReportResults extends Component {

    render() {
        return (
            <div id="survey-report-container">
                <Header/>
                    <div className='survey-reports-wrapper'>
                        <ReportResults/>
                    </div>
                <Footer/>
            </div>
        );
    }
}

export default ContactReportResults;